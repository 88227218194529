import type React from 'react'

import { Footer } from './Footer'
import { Navbar } from './Navbar'

export const Layout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <div>
      <Navbar />
      {children}
      <Footer />
    </div>
  )
}
