export const comments = [
  'Thanks for your time, and the way you made this all so easy!',
  'This is everything I needed and wish I had before my 1st time taking the Health insurance license exam. totally get it now, thank you!!',
  'Wasn’t quite sure how Credit Cards worked until I saw this video. Thank you for posting this!',
  'Thank you this was very helpful and informative 👍👍',
  "Wow. Tbh this video makes school seem so useless.... they don't teach us anything we need in life.",
  "I'm a 7th grade homeschooler wanting to learn about economics and how the world works, and this helped out A LOT. Thank you!",
  'Wow that explained everything  i needed to know in under two minutes',
  'This channel is a blessing',
  'Watching this as a 14 year old after I tried to get my mum and my maths teacher to explain how taxes work. Thanks, this is much better then what mum told me and my maths teacher taught me nothing on this.',
  'Very helpful videos, I really wish we would have gotten this from of education in school. My life out of high school would have been way easier',
  'Thank you so much for posting this video , you really helped me the most based on how to figure out tax returns terminologies meaning and also how they are mathematically applied on tax return auditing.',
  'GREAT VIDEO, I have been watching a few of these today, and yours was the best yet. Thanks!',
  "I’m taking a class called Money 101 and we’re “learning” about taxes but now I’m just sooooo much more confused. I keep getting frustrated so I’m watching this video and it explains it way better. Unlike in that class, the video doesn’t throw around random words I don't know about",
  'Watching this as a 14 year old after I tried to get my mum and my maths teacher to explain how taxes work thanks this is much better then what mum told me and my maths teacher taught me nothing on this',
  'That was the best n easiest way I heard someone explain taxes 👍',
  'I know this may not mean a lot but I Love the diversity in the videos ... it really means a lot. It shows representation that we all go through',
  'Like another commenter, I found this invaluable. It was the right details presented in an easy to digest format. Thank you!',
  'These videos are simple and to the point. Thank you very much for offering this information for free. I wish I would of found MoneyCoach in high school, but now is better than never!',
  'Thank you so much for this. I’m 17 1/2 and getting ready for the adult world.',
  'If only SCHOOL would teach this! 😭😭😭',
  'This is a great and informative video for people to understand the differences between debit and credit.',
  'It should be mandatory for this to be shown in schools',
  'As an adult, I used to still get confused by this.. until now lol',
  'Just a 16 year getting ready for the real world don’t mind me',
  'Very informative videos, thanks for creating them!',
  'You explained how cashback worked really well. Thanks for the great points',
]
